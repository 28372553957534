import logo from "./logo.png"

const clientData = {
    client_entity: 14,
    attorney_firm: 'ERTC Help Center',
    attorney_name: 'Harry Talmage',
    attorney_number: '601-317-0127',
    attorney_email: 'harry@helpcenterertc.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_help_center+(720p).mp4',
    mainColor: '#242424',
    secondaryColor: '#00268d',
    siteLink: 'http://flowerslawoffice.com/',
    logo
}

export default clientData